@font-face {
  font-family: 'norwester';
  src: url('/fonts/norwester.eot');
  src: url('/fonts/norwester.eot') format('embedded-opentype'),
       url('/fonts/norwester.woff2') format('woff2'),
       url('/fonts/norwester.woff') format('woff'),
       url('/fonts/norwester.ttf') format('truetype'),
       url('/fonts/norwester.svg#norwester') format('svg');
}

@font-face{
  font-family: "auraregular";
  src: url("/fonts/Aura-Regular.ttf") format("truetype");
}

@font-face{
  font-family: "worksans";
  src: url("/fonts/WorkSans-Regular.ttf") format("truetype");
}

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  overflow: hidden;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

ul, ol {
  padding-left: 0;
}

.email {
  color: #2c2cea;
  font-weight: 400;
}

.background-light {
  background-color: #efefef;
}

.bg-mesasage-seen {
  background-color: #d7d7d7;
}

.bt-transform {
  transform: translateY(5px)
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%
}

.pagination {
  margin-top: 10px;
}

.pagination li {
  padding: 0;
  background-color: #72a5e6;
  color: #fff;
  margin-right: 2px;
}

.pagination li a, .pagination li a:hover {
  cursor: pointer;
  color: inherit;
  font-weight: 400;
  padding: .5rem 1rem;
  display: block;
}

.text-wrapp {
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow: ellipsis;
}

.RSPBprogressBar{
  max-width: 50%;
  min-width: 40% !important;
  margin: 0px 10px;
}

.RSPBprogressBar .RSPBstep p {
  color: #605f5f;
  font-family: worksans;
  font-size: 14px;
  font-weight: 500;
  
}
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}
.dropbtn2 {
  background-color: lightgrey;
  color: rgb(87, 112, 131);
  font-weight: bold;
  padding: 9px;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}
.dropbt2n:hover, .dropbtn2:focus {
  background-color: lightgrey;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content2 {
  margin-top: 15px;
  margin-left: 15px;
  display: none;
  width: 750px;
}

.dropdown-content2 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content3 {
  margin-top: 15px;
  margin-left: 15px;
  display: none;
  width: 750px;
}

.dropdown-content3 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}
.hide {display: none;}
.showX {display: inline;}
#try{
  display: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
