body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'norwester';
  src: url('/fonts/norwester.eot');
  src: url('/fonts/norwester.eot') format('embedded-opentype'),
       url('/fonts/norwester.woff2') format('woff2'),
       url('/fonts/norwester.woff') format('woff'),
       url('/fonts/norwester.ttf') format('truetype'),
       url('/fonts/norwester.svg#norwester') format('svg');
}

@font-face{
  font-family: "auraregular";
  src: url("/fonts/Aura-Regular.ttf") format("truetype");
}

@font-face{
  font-family: "worksans";
  src: url("/fonts/WorkSans-Regular.ttf") format("truetype");
}

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  overflow: hidden;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

ul, ol {
  padding-left: 0;
}

.email {
  color: #2c2cea;
  font-weight: 400;
}

.background-light {
  background-color: #efefef;
}

.bg-mesasage-seen {
  background-color: #d7d7d7;
}

.bt-transform {
  -webkit-transform: translateY(5px);
          transform: translateY(5px)
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%
}

.pagination {
  margin-top: 10px;
}

.pagination li {
  padding: 0;
  background-color: #72a5e6;
  color: #fff;
  margin-right: 2px;
}

.pagination li a, .pagination li a:hover {
  cursor: pointer;
  color: inherit;
  font-weight: 400;
  padding: .5rem 1rem;
  display: block;
}

.text-wrapp {
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow: ellipsis;
}

.RSPBprogressBar{
  max-width: 50%;
  min-width: 40% !important;
  margin: 0px 10px;
}

.RSPBprogressBar .RSPBstep p {
  color: #605f5f;
  font-family: worksans;
  font-size: 14px;
  font-weight: 500;
  
}
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}
.dropbtn2 {
  background-color: lightgrey;
  color: rgb(87, 112, 131);
  font-weight: bold;
  padding: 9px;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}
.dropbt2n:hover, .dropbtn2:focus {
  background-color: lightgrey;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content2 {
  margin-top: 15px;
  margin-left: 15px;
  display: none;
  width: 750px;
}

.dropdown-content2 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content3 {
  margin-top: 15px;
  margin-left: 15px;
  display: none;
  width: 750px;
}

.dropdown-content3 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}
.hide {display: none;}
.showX {display: inline;}
#try{
  display: none;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.welcome-header {
  /* background-image: url(/images/bannerwithoverlay.png); */
  background: #ffffff;
  /* background: #000; */
  border-bottom: 3px solid lightgrey;
  height: 9%;
  /* padding: 19px; */
  position: relative;
  z-index: 1000;
}

.welcome-header .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.welcome-header-forgot {
  background-image: url(/images/bannerwithoverlay.png);
  /* background: #000; */
  height: 148px;
  padding: 19px;
  position: relative;
  z-index: 1000;
}

.welcome-header-forgot .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.logo-image-leftcol img {
  max-width: 110px !important;
}

.heading-text h3 {
  color: #fff;
  margin-top: 22px;
  margin-left: 20px;
}

.heading-text h3:first-child {
  font-size: 24px;
  font-weight: 400;
}

.heading-text h3:last-child {
  font-size: 22px;
  font-weight: 500;
}

.welcome-main-content {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 15px;
}

.welcome-coach-content {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px 4px 0 0;
  padding: 30px;
  margin-top: 0px;
}

.welcome-coach-content h1 {
  color: #fff;
  font-weight: 400;
  margin: 40px;
  font-size: 26px;
}

.welcome-main-content h2,
.welcome-main-content h4 {
  color: #fff;
  font-weight: 400;
}

.welcome-main-content h2 {
  font-size: 30px;
  margin-bottom: 20px;
}
.welcome-coach-content .btn {
  /* background: #58C051 !important; */
  color: #fff !important;
  padding: 0;
}
.welcome-coach-content .btn a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 39px;
  display: block;
}

.register-coach-main-content {
  background-repeat: no-repeat;
  background-size: cover;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  /* min-height: 160vh; */
}

.register-coach-main-content .first-row {
  padding-top: 20px;
}

.register-coach-main-content h1,
.register-coach-main-content h2,
.register-coach-main-content h3,
.register-coach-main-content h4 {
  color: #fff;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 10px;
}

.register-coach-main-content h1 {
  font-size: 36px;
}

.register-coach-main-content h3 {
  font-size: 24px;
  font-weight: 400;
}

.register-coach-main-content .form-box {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  /* padding: 0 25px 15px 25px; */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px 4px 0 0;
  text-align: left;
  padding: 33px;
  margin-bottom: 30px;
}

.register-coach-main-content .form-top-left p {
  color: #fff;
}

.register-coach-main-content .join-form {
  margin-bottom: 20px;
}

.register-coach-main-content .join-form .form-group {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
}

.register-coach-main-content .join-form .form-group .form-control {
  font-size: 12px !important;
}

.register-coach-main-content input,
.register-coach-main-content select {
  height: 40px;
  margin: 0;
  padding: 0 15px;
  vertical-align: middle;
  background: #fff;
  border: 3px solid #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  color: #888;
  border-radius: 4px;
  box-shadow: none;
  transition: all 0.3s;
}

.join-form .btn {
  padding: 8px 28px;
  font-size: 16px;
  font-weight: 400;
}

.coach-terms {
  text-align: center;
  padding: 20px;
  border-top: 1px solid #c4cdd3 !important;
  color: #c4cdd3;
}

.coach-terms a {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  font-weight: 400;
}

.coachsteps-common-background {
  background: rgba(0, 0, 0, 0.8);
  padding: 30px;
  margin-top: 80px;
  margin-bottom: 80px;
}

.register-coach-main-content input.joinslackCheckbox {
  height: 22px;
}

.coachsteps-common-background h4 {
  font-size: 18px;
}
.register-coach-main-content .btn-continue {
  padding: 10px 40px;
  font-weight: 500;
  margin-top: 20px;
}
.register-coach-main-content .btn-background {
  background-color: #d4eaf7;
  border-color: #d4eaf7;
  color: #53acdf;
  padding: 0;
}

.register-coach-main-content .btn-background a {
  color: inherit;
  padding: 10px 40px;
  display: block;
  width: 100%;
  text-decoration: none;
}

.non-compete-info-h {
  text-align: left;
  font-weight: 400 !important;
}

.non-compete-info-p {
  color: #fff;
  text-align: left;
  padding-left: 30px;
  padding-right: 15px;
  font-weight: 400;
}

.modal-review-contract {
  max-width: 70%;
}

.modal-review-contract .custom-font {
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  .modal-review-contract {
    max-width: 80%;
    width: 80%;
  }
}

/* Novo dodano moje */
.register-welcome-container {
  width: 60%;
  margin: auto;
  background: #ffffff;
  border-radius: 70px;
  border: 5px solid blue;
  padding: 30px;
  display: flex;
  justify-content: space-around;
}

.register-welcome-container2 {
  width: 60%;
  margin: auto;
  background: #ffffff;
  border-radius: 70px;
  border: 5px solid rgb(43, 43, 44);
  padding: 50px;
  display: flex;
  justify-content: space-around;
}

.nobg-container {
  background: transparent;
  border: 0px;
}

.part1-policies-container {
  border: 5px solid rgb(43, 43, 44);
}

.leftside-container-special {
  font-size: 20px;
  width: 50%;
  color: rgb(10, 133, 173);
  font-weight: 600;
}

.leftside-container-special2 {
  font-size: 20px;
  width: 50%;
  color: rgb(10, 133, 173);
  font-weight: 600;
}

.notice-container {
  background: #ffffff;
  border-radius: 30px;
  border: 5px solid rgb(43, 43, 44);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: space-around;
}

.register-first-shadow {
  box-shadow: 10px 20px #d4a9db, 10px 20px 0 5px blue;
}

.register-second-shadow {
  box-shadow: 10px 20px #0a85ad, 10px 20px 0 5px blue;
}

.register-sixteenth-shadow {
  box-shadow: 10px 20px #10a7da, 10px 20px 0 5px rgb(43, 43, 44);
}

.register-notice-container-shadow {
  box-shadow: 5px 10px #dee1e2, 5px 10px 0 5px rgb(43, 43, 44);
}

.register-notice-container-shadow-yellow {
  box-shadow: 10px 20px lightyellow, 10px 20px 0 5px rgb(43, 43, 44);
}

.register-notice-container-shadow-yellow-strong {
  box-shadow: 10px 20px #f7f76f, 10px 20px 0 5px rgb(43, 43, 44);
}

.register-small-notice-container-shadow {
  box-shadow: 7px 7px #dee1e2, 7px 7px 0 5px rgb(43, 43, 44);
}

.register-small-notice-container-shadow-yellow {
  box-shadow: 7px 7px lightyellow, 7px 7px 0 5px rgb(43, 43, 44);
}

.register-small-notice-container-shadow-lightblue {
  box-shadow: 7px 7px #0d7ba0, 7px 7px 0 5px rgb(43, 43, 44);
}

.register-part1-policies-container-shadow {
  box-shadow: 10px 20px #0d7ba0, 10px 20px 0 5px rgb(43, 43, 44);
}

.register-notice-container-yellow {
  box-shadow: 10px 20px lightyellow, 10px 20px 0 5px rgb(43, 43, 44);
}

.register-notice-container-yellow-small {
  box-shadow: 7px 7px lightyellow, 7px 7px 0 5px rgb(43, 43, 44);
}

.register-round-container {
  padding: 10px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 40px;
  width: 120px;
  font-weight: 700;
  color: rgb(43, 43, 44);
  border: 5px solid rgb(43, 43, 44);
}

.flex-container-positioning {
  display: flex;
  justify-content: space-evenly;
}

.gear-box {
  width: 120px;
  height: 120px;
  background: #ffffff;
  border-radius: 25px;
  border: 5px solid rgb(43, 43, 44);
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-round-containing-container {
  display: flex;
  margin-bottom: 30px;
}

.register-next-to-round-container {
  margin-left: 40px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.register-coach-video-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.register-coach-icon {
  width: 100px;
  height: 100px;
}

.big-ninth-container {
  display: flex;
}

.rule-box {
  width: 40%;
  height: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.register-coach-button {
  border-radius: 10px;
  background-color: #f7f76f;
  width: 200px;
  padding: 5px;
  font-size: 25px;
  border: 4px solid black;
  font-weight: 600;
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .register-welcome-container {
    flex-direction: column;
  }
  .big-container-reg {
    padding: 20px;
  }

  .big-ninth-container {
    flex-direction: column;
  }
}

.part-text-container {
  font-size: 150px;
}

@media screen and (max-width: 600px) {
  .big-container-reg {
    padding: 10px;
  }
  .register-welcome-container {
    width: 100%;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    padding-bottom: 100px;
    box-shadow: 0px 0px, 0px 0px;
  }

  .register-next-to-round-container {
    margin-left: 0px;
  }

  .register-round-container {
    display: none;
  }

  .part-text-container {
    font-size: 80px;
  }

  .heading-text-text {
    display: none;
  }

  .flex-container-positioning {
    flex-direction: column;
    align-items: center;
  }

  .gear-box {
    margin-top: 20px;
  }

  .leftside-container-special {
    flex-direction: column;
    width: 100%;
  }

  .big-ninth-container {
    flex-direction: column;
  }

  .leftside-container-special {
    display: none;
  }

  .leftside-container-special2 {
    display: none;
  }

  .icon-13-classes {
    display: none;
  }

  .new-heading-text {
    display: none !important;
  }

  #coachregicon {
    display: none;
  }
}

/* Novo dodano moje */

